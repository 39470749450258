<template>
  <el-card>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="rejectCheckBlackList" name="fourth">
        <reject-check-white-list />
      </el-tab-pane>
      <el-tab-pane label="curve black list" name="first">
        <curve-black-list />
      </el-tab-pane>
      <el-tab-pane label=" adjustPb black list" name="second">
        <adjust-blacklist />
      </el-tab-pane>
      <el-tab-pane label="subSlow black List" name="third">
        <sub-slow-black-list />
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>
<script>
  import curveBlackList from './curveBlackList.vue';
  import adjustBlacklist from '../adjustBlacklist.vue';
  import subSlowBlackList from '../subSlowBlackList.vue';
  import rejectCheckWhiteList from '../whitelist.vue';

  export default {
    components: {
      curveBlackList,
      adjustBlacklist,
      subSlowBlackList,
      rejectCheckWhiteList,
    },
    data() {
      return {
        activeName: 'fourth',
      };
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
    },
  };
</script>
