<template>
  <div class="adjustBlacklist">
    <!-- <div class="main-Title bgff"><h2>White List</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="mb10">
        <el-form v-model="filterForm" size="small" label-width="60px">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="7">
              <el-form-item label="OfferId:" class="mb0">
                <el-input
                  v-model.number="filterForm.offerId"
                  placeholder="Please enter offer id"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="7">
              <el-form-item label-width="0" class="mb0">
                <el-button type="primary" :loading="loading.list" @click="getList(1)"
                  >Search
                </el-button>
                <el-button type="success" @click="openDialog('add')">batch create</el-button>
                <el-button type="danger" @click="batchDeletes">batch delete</el-button>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="7">
              <p class="hover-text">
                用于设置渠道拉单，offer维度hourCap曲线黑名单，即在曲线黑名单中的offerId，hourCap平均送量(dailyCap除以24)。</p
              >
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="loading.list"
          ref="tableRef"
          style="width:100%;min-height: 70vmin;"
          stripe
          border
          :data="list"
          highlight-current-row
          aria-setsize="mini"
          size="mini"
          class="mb10"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" align="center"></el-table-column>
          <el-table-column label="offerId" prop="offerId" align="center"></el-table-column>
          <el-table-column label="createTime" prop="createTime" align="center"></el-table-column>
          <el-table-column label="operation" align="center">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="deletes(scope.row.offerId)"
                >Delete
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-dialog :title="dialog.title" :visible.sync="dialog.visible" width="30%">
          <el-form
            v-model="postData"
            size="small"
            :model="postData"
            ref="postDataForm"
            label-width="80px"
            :rules="rules"
          >
            <el-form-item label="OfferId" prop="offerId">
              <el-input
                v-model.trim="postData.offerIds"
                placeholder="输入offerId用,隔开，例子：8145,8143"
              ></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog('postDataForm')" size="mini">取 消</el-button>
            <el-button
              type="primary"
              @click="submitDialog('postDataForm')"
              :loading="loading.submitBtn"
              >确 定</el-button
            >
          </span>
        </el-dialog>
        <!--        <pagination
                  :pageTotal="total"
                  @handleSizeChange="handleSizeChange"
                  @handleCurrentChange="handleCurrentChange"
                  :pageSize="filterForm.pageSize"
                  :currentPage="filterForm.page"
                ></pagination>-->
      </el-card>
    </div>
  </div>
</template>

<script>
  import { fetchCurveBlackList, addCurveBlackList, removeCurveBlackList } from 'api/setting';
  // import Pagination from "@/components/pagination";

  export default {
    components: {
      // Pagination
    },
    data() {
      return {
        filterForm: {
          page: 1,
          pageSize: 20,
        },
        total: 0,
        postData: {},
        list: [],
        loading: {
          list: false,
        },
        dialog: {
          visible: false,
          title: '--',
          type: '',
        },
        rules: {
          /*offerId: [
          { required: true, message: 'offerId不能为空' },
          { type: 'number', message: 'offerId必须为数字值' },
        ],*/
        },
      };
    },
    mounted() {
      this.getList();
    },
    methods: {
      // 获取列表
      getList(page) {
        if (page) {
          this.filterForm.page = page;
        }
        this.loading.list = true;
        fetchCurveBlackList(this.filterForm)
          .then((res) => {
            console.log(res);
            if (res.code == 200) {
              this.list = res.result;
              this.total = res.total;
            } else {
              this.$message.error(res.message);
            }
            this.loading.list = false;
          })
          .catch((e) => {
            this.loading.list = false;
            this.$message.error(e);
          });
      },
      // 删除
      deletes(id) {
        this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        })
          .then(() => {
            removeCurveBlackList({ offerIds: [id] }).then((res) => {
              if (res.code == 200) {
                this.$message.success('删除成功！');
                this.getList();
              } else {
                this.$message.error(res.message);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
      },
      batchDeletes() {
        this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        })
          .then(() => {
            var offerIds = [];
            for (let i = 0; i < this.multipleSelection.length; i++) {
              offerIds.push(this.multipleSelection[i].offerId);
            }
            removeCurveBlackList({ offerIds: offerIds }).then((res) => {
              if (res.code == 200) {
                this.$message.success('删除成功！');
                this.getList();
              } else {
                this.$message.error(res.message);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
      },
      // 打开弹窗
      openDialog(type, row) {
        this.dialog.type = type;
        this.dialog.title = type == 'add' ? '添加' : '编辑';
        this.dialog.visible = true;
        if (row) {
          this.postData = Object.assign({}, row);
        }
      },
      // 提交弹窗
      submitDialog(formName) {
        console.log(formName);
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading.submitBtn = true;
            if (this.dialog.type == 'add') {
              let offerIds = this.postData.offerIds.split(',');
              this.postData.offerIds = offerIds;
              addCurveBlackList(this.postData).then((res) => {
                if (res.code == 200) {
                  this.$message.success('添加成功!');
                  this.loading.submitBtn = false;
                  this.closeDialog(formName);
                  this.getList();
                } else {
                  this.$message.error(res.message);
                }
              });
            }
            if (this.dialog.type == 'edit') {
              console.log('编辑操作');
            }
          }
        });
      },
      // 关闭弹窗
      closeDialog(formName) {
        this.$refs[formName].resetFields();
        this.dialog.visible = false;
      },
      handleSizeChange(size) {
        this.filterForm.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.filterForm.page = currentPage;
        this.getList();
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
        let copyArr = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          copyArr.push(this.multipleSelection[i].offerId);
        }
        console.log(copyArr);
      },
    },
  };
</script>

<style scoped>
  .hover-text {
    font-size: 1em; /* 初始字体大小 */
    line-height: 1.6; /* 行高设置，防止文字重叠 */
    transition: all 0.3s ease; /* 平滑过渡所有属性 */
    color: #333; /* 初始字体颜色 */
  }

  .hover-text:hover {
    font-size: 1.5em; /* 鼠标悬停时字体变大 */
    color: #007bff; /* 鼠标悬停时字体颜色变为蓝色 */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* 添加阴影效果 */
  }
</style>
